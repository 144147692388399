export const UPDATE_POST_MESSAGE = "UPDATE_POST_MESSAGE";
export const UPDATE_CLONE_POST_MESSAGE = "UPDATE_CLONE_POST_MESSAGE";
export const SET_POST_MESSAGE = "SET_POST_MESSAGE";
export const SET_TEMPLATE_ORDER = "SET_TEMPLATE_ORDER";
export const UPDATE_WELCOME_TEMPLATE = "UPDATE_WELCOME_TEMPLATE";
export const UPDATE_ABOUT_US_TEMPLATE = "UPDATE_ABOUT_US_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_CONTENT = "UPDATE_TEMPLATE_CONTENT";
export const UPDATE_PALETTE = "UPDATE_PALETTE";
export const UPDATE_CUSTOM_PALETTE = "UPDATE_CUSTOM_PALETTE";
export const UPDATE_SECTION_SETTINGS = "UPDATE_SECTION_SETTINGS";
export const UPDATE_FONT_SETTINGS = "UPDATE_FONT_SETTINGS";
export const UPDATE_ANIMATION_SETTINGS = "UPDATE_ANIMATION_SETTINGS";
export const UPDATE_TEMPLATE_ACTIVE = "UPDATE_TEMPLATE_ACTIVE";
export const SET_TO_INITIAL = "SET_TO_INITIAL";
