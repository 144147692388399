import gql from "graphql-tag";

export const getShopDetails = gql`
  query GetShopDetails($ownerShopId: String) {
    getShopDetails(ownerShopId: $ownerShopId) {
      userId
      shopName
      userName
      userPhoneNumber
      userAddress
      shopAddress
      userPincode
      userEmail
      userDOB
      customDomainUrl
      shopType
      shopGST
      loyaltyRatio
      loyaltyAmount
      shopImageLocation
      userImageLocation
      customerAddedShopToFav
      userId10Digit
      messageDetailsMap
      customerPhoneNumberMap
      paymentMethod
      paymentNumber
      loyaltyPackage
      membershipId
      membershipExpiry
      shopTimings
      taxRate
      employee
      billingPackage
      deliverySlot
      minBillAmount
      chargeForDelivery
      typeOfService
      typeOfPayment
      userArea
      userCoords
      pin
      appointmentPackage
      brandList
      shopLandmark
      safetyInCovid
      onlineStoreLive
      websiteStatus
      websiteUserName
      ownerShopId
      shopIconLocation
      instaLink
      fbLink
      shopDescription
      userBillingSettings
      packageDetails
      gamePoints
      referralCode
      websiteUrl
      websiteContactPhoneNumber
      # deliveryChargeMap
      razorpayLinkedAccount
      referredBy
      websiteTemplate
      deliveryPartnerAdded
      deliveryPartners
      googleAddress
      categoryList
    }
  }
`;
