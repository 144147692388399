import {
  Button,
  Card,
  Form,
  InputNumber,
  Layout,
  Modal,
  Typography,
  message,
} from "antd";
import ActionButton from "./Buttons/button";
import config from "../config";
import { useState } from "react";
import {
  UPDATE_CLONE_POST_MESSAGE,
  UPDATE_POST_MESSAGE,
} from "../redux/postMessage/postMessageType";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useCurrentShopId } from "../provider/currentShopIdProvider";
import { useNavigate } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Auth } from "aws-amplify";

const { Header } = Layout;
const { Title } = Typography;

const InternalHome = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSignModal, setIsSignModal] = useState(false);
  const defaultPostMessage = useSelector(
    (state) => state.postMessage.defaultPostMessage
  );
  const [, setCurrentShopId] = useCurrentShopId();
  const handleSubmit = async (values) => {
    setLoading(true);
    const userId10Digit = values.userId10Digit;
    try {
      const response = await fetch(
        config.posApiGateway + "/ownDomainFetchContent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId10Digit,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        const shopDetails = data.data;
        setCurrentShopId(shopDetails.userId);
        if (shopDetails) {
          if (!shopDetails.websiteTemplate) {
            const payload = {
              shopDetails: {
                shopName: shopDetails.shopName,
                shopAddress: shopDetails.shopAddress,
                shopId: shopDetails.userId,
                shopType: shopDetails.shopType,
                websiteTemplate: defaultPostMessage.pageProps,
                websiteContactPhoneNumber: shopDetails.websiteContactPhoneNumber
                  ? shopDetails.websiteContactPhoneNumber
                  : shopDetails.userPhoneNumber,
                shopEmail: shopDetails.userEmail,
                description: shopDetails.shopDescription,
                facebookLink: shopDetails.facebookLink,
                instagramLink: shopDetails.instagramLink,
                websiteStatus: shopDetails.websiteStatus,
                websiteUrl: shopDetails.websiteUrl,
                customDomainUrl: shopDetails.customDomainUrl,
                shopTimings: shopDetails.shopTimings,
              },
              pageProps: defaultPostMessage.pageProps,
            };
            dispatch({
              type: UPDATE_POST_MESSAGE,
              payload: payload,
            });
            dispatch({
              type: UPDATE_CLONE_POST_MESSAGE,
              payload: payload,
            });
            navigate("/");
          } else if (shopDetails.websiteTemplate) {
            let websiteTemplate = shopDetails.websiteTemplate;
            console.log("websiteTemplate1", websiteTemplate);

            if (!websiteTemplate?.["colorTheme"]) {
              websiteTemplate = {
                ...websiteTemplate,
                colorTheme: defaultPostMessage.pageProps["colorTheme"],
              };
            }
            console.log("websiteTemplate2", websiteTemplate);
            websiteTemplate["colorPallet"] =
              defaultPostMessage.pageProps["colorPallet"];
            websiteTemplate["videoGallery"] = {
              ...websiteTemplate["videoGallery"],
              pageProps: {
                ...websiteTemplate?.["videoGallery"]?.["pageProps"],
                cardData:
                  websiteTemplate?.["videoGallery"]?.[
                    "pageProps"
                  ]?.cardData?.map((i) => {
                    let tempVideoUrl = i.videoUrl;
                    return {
                      ...i,
                      videoUrl: "",
                      tempVideoUrl: tempVideoUrl,
                    };
                  }) || [],
              },
            };
            const payload = {
              shopDetails: {
                shopName: shopDetails.shopName,
                shopAddress: shopDetails.shopAddress,
                shopId: shopDetails.userId,
                shopType: shopDetails.shopType,
                websiteTemplate: websiteTemplate,
                websiteContactPhoneNumber: shopDetails.websiteContactPhoneNumber
                  ? shopDetails.websiteContactPhoneNumber
                  : shopDetails.userPhoneNumber,
                shopEmail: shopDetails.userEmail,
                description: shopDetails.shopDescription,
                facebookLink: shopDetails.facebookLink,
                instagramLink: shopDetails.instagramLink,
                websiteStatus: shopDetails.websiteStatus,
                websiteUrl: shopDetails.websiteUrl,
                customDomainUrl: shopDetails.customDomainUrl,
                shopTimings: shopDetails.shopTimings,
              },
              pageProps: websiteTemplate,
            };
            console.log("payload22", payload);
            dispatch({
              type: UPDATE_POST_MESSAGE,
              payload,
            });
            dispatch({
              type: UPDATE_CLONE_POST_MESSAGE,
              payload,
            });
            navigate("/");
          }
        }
      } else if (data.status === "ERROR") {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setIsSignModal(false);
  };
  const handleSignOut = () => {
    Auth.signOut()
      .then((res) => {
        dispatch({
          type: "IS_LOGGEDIN",
          payload: false,
        });
        localStorage.clear();
        navigate("/internal-login");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#fff",
          boxShadow: "0px 6px 15px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Title style={{ color: "#EF4667", margin: "0" }} level={3}>
          Ghoshak
        </Title>
        <Button
          type="link"
          icon={
            <RiLogoutCircleRLine
              size={18}
              style={{
                verticalAlign: "sub",
                marginRight: "0.5rem",
              }}
            />
          }
          onClick={() => {
            setIsSignModal(true);
          }}
        >
          <span
            style={{
              fontSize: 18,
            }}
          >
            Logout
          </span>
        </Button>
      </Header>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: "100%",
            marginTop: "-8rem",
            maxWidth: "30%",
            boxShadow: "-3px 13px 27px 4px rgba(0,0,0,0.1)",
          }}
          title="Enter Customer 10 Digit Shop Id"
        >
          <Form onFinish={handleSubmit}>
            <Form.Item
              name={"userId10Digit"}
              rules={[
                { required: true, message: "Enter Shop Id" },
                {
                  type: "number",
                  message: "Please enter valid shop id",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                size="large"
                type="number"
              />
            </Form.Item>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <ActionButton
                htmlType="submit"
                style={{
                  borderRadius: "8px",
                  margin: "0 auto",
                }}
                loading={loading}
              >
                SUBMIT
              </ActionButton>
            </div>
          </Form>
        </Card>
      </div>
      <Modal
        visible={isSignModal}
        onClose={handleClose}
        onCancel={handleClose}
        footer={false}
        centered
      >
        <h5
          style={{
            fontSize: "1.5rem",
          }}
        >
          Are you sure you want to sign out?
        </h5>
        <div
          style={{
            marginTop: "1rem",
            textAlign: "right",
          }}
        >
          <Button
            type="danger"
            style={{
              width: "100px",
              backgroundColor: "#EF4667",
            }}
            onClick={handleSignOut}
          >
            Yes
          </Button>
          <Button
            onClick={() => setIsSignModal(false)}
            style={{ marginLeft: "2rem", width: "100px" }}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InternalHome;
