import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Input, message } from "antd";
import ActionButton from "./Buttons/button";
import Bgimage from "../assets/Login/Group.svg";
import Logo from "../assets/Logo.svg";
import { FaPhoneAlt } from "react-icons/fa";
import OtpImg from "../assets/Login/otpImg.svg";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { IS_LOGGEDIN } from "../redux/login/loginType";
import Countdown from "antd/lib/statistic/Countdown";

const Login = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState();
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isResend, setIsResend] = useState(false);
  const [loading, setLoading] = useState(false);
  let otpCountDownSecond = Date.now() + 1000 * 30;

  const verifyPhoneNumber = () => {
    const regExp = /^[0]?[56789]\d{9}$/;
    if (phoneNumber === "") {
      alert("Enter phone number");
      return;
    }
    if (regExp.test(phoneNumber) === false) {
      alert("Enter valid phone number");
      return;
    }
    LogInValidation(phoneNumber);
  };

  const handleResend = () => {
    otpCountDownSecond = Date.now() + 1000 * 30;
    message.success("OTP has been sent to your mobile number");
    setIsResend(true);
    verifyPhoneNumber();
  };

  const onLoginSubmit = (values) => {
    if (values.phone_number) {
      setLoading(true);
      LogInValidation(values.phone_number);
    }
    setPhoneNumber(values.phone_number);
  };

  // const loginProcess = async () => {
  //   await Auth.signIn(`+91${phoneNumber}`)
  //     .then((response) => {
  //       if (response.challengeName === "CUSTOM_CHALLENGE") {
  //         setUser(response);
  //         if (resendOtpFlag) {
  //           setResendOtpFlag(false);
  //           alert("New OTP has been sent to your number");
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.code === "NetworkError") {
  //         setLoading(false);
  //         return alert("Please check your network connection and try again");
  //       }
  //       if (err.code === "UserNotFoundException") {
  //         if (userType === "employee") {
  //           alert("Account not found. Please contact your shop owner.");
  //           setLoading(false);
  //           setConfirmLoading(false);
  //           return;
  //         }
  //         alert("Account not found. Please Sign up.");
  //         setLoading(false);
  //         setConfirmLoading(false);
  //         setUserSignUp(true);
  //         setFrom("signup");
  //       }
  //     });
  // };

  async function LogInValidation(phoneNumber) {
    const params = {
      userPhoneNumber: `+91${phoneNumber}`,
    };
    console.log(params);
    await Auth.signIn(params.userPhoneNumber)
      .then((e) => {
        console.log("e", e);
        if (e.challengeName === "CUSTOM_CHALLENGE") {
          setIsLogin(true);
          setUser(e);
        }
      })
      .catch((e) => {
        if (e.code === "NetworkError") {
          return alert("Please check your network connection and try again");
        }
        if (e.code === "UserNotFoundException") {
          message.warning("User Not Found!");
        } else {
          message.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const confirmOtp = () => {
    setConfirmLoading(true);
    const params = {
      user: user,
      otp: otp,
    };
    Auth.sendCustomChallengeAnswer(params.user, params.otp)
      .then((user) => {
        if (user.signInUserSession === null) {
          message.error("You have entered a wrong OTP");
          setConfirmLoading(false);
          setIsResend(false);
          setOtp("");
        } else {
          dispatch({
            type: IS_LOGGEDIN,
            payload: true,
          });
          navigate("/");
          setConfirmLoading(false);
        }
      })
      .catch((e) => {
        setConfirmLoading(false);
        Auth.signOut();
      });
  };

  const handleTriggerResend = () => {
    setIsResend(false);
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.keyCode === 13) {
          confirmOtp();
        }
      },
      false
    );
    return () => {
      document.removeEventListener(
        "keydown",
        (e) => {
          if (e.keyCode === 13) {
            confirmOtp();
          }
        },
        false
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row
        style={{
          height: "100vh",
        }}
        align="middle"
      >
        <Col
          span={12}
          style={{
            background: `url(${Bgimage}), linear-gradient(180deg, #EF4667 0%, #A00E2A 84.79%, #7A0B20 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "inherit",
            backgroundPosition: "bottom left",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "3rem",
          }}
        >
          <h1
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#fff",
              lineHeight: "40px",
            }}
          >
            Ghoshak <br />
            Your Store Partner
          </h1>
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
              fontWeight: "400",
              fontFamily: "HK Grotesk",
              lineHeight: "24px",
            }}
          >
            Manage Sales, Inventory and Employees with ease; Engage customers
            and increase your revenue.
          </p>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col span={14}>
            {isLogin ? (
              <>
                <img src={OtpImg} alt="OtpImg" width={"50px"} height="50px" />
                <h2 style={{ fontSize: "28px", margin: "1rem 0" }}>OTP Sent</h2>
                <Form.Item>
                  <OtpInput
                    value={otp}
                    onChange={(v) => setOtp(v)}
                    shouldAutoFocus={true}
                    numInputs={6}
                    inputStyle={{
                      width: "43px",
                      height: "35px",
                      background: "#FFFFFF",
                      border: "0.8px solid #DCDCDC",
                      boxShadow:
                        " 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.18)",
                      borderRadius: "4px",
                      marginRight: "20px",
                    }}
                    isInputNum={true}
                  />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginTop: "1rem",
                    justifyContent: "start",
                  }}
                >
                  <p style={{ margin: 0 }}>Didn’t receive the code?</p>
                  <Button
                    type="link"
                    disabled={isResend}
                    onClick={handleResend}
                    style={{
                      paddingRight: "0",
                    }}
                  >
                    Resend Code
                  </Button>
                  {isResend && (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                      }}
                    >
                      in
                      <Countdown
                        valueStyle={{ fontSize: 16, display: "inline-block" }}
                        value={otpCountDownSecond}
                        format="s"
                        onFinish={handleTriggerResend}
                      />
                    </div>
                  )}
                </div>
                <ActionButton
                  onClick={confirmOtp}
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  size="large"
                  disabled={confirmLoading}
                  loading={confirmLoading}
                >
                  Confirm Otp
                </ActionButton>
              </>
            ) : (
              <>
                <img src={Logo} width="88px" height="28px" alt="logo" />
                <h4
                  style={{
                    color: "#98989E",
                    fontSize: "16px",
                    margin: "1rem 0",
                  }}
                >
                  Welcome Back!
                </h4>
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  Login
                </h1>
                <Form
                  onFinish={onLoginSubmit}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Form.Item
                    label="Mobile Number"
                    name={"phone_number"}
                    style={{
                      fontSize: "12px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please input a valid phone number!",
                      },
                      {
                        pattern: /^\S/,
                        required: true,
                        message:
                          "Empty space is not allowed as the first character",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      prefix={
                        <FaPhoneAlt
                          style={{
                            color: "#98989E",
                            marginRight: "8px",
                          }}
                        />
                      }
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Your Mobile Number"
                    />
                  </Form.Item>
                  <ActionButton
                    htmlType="submit"
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    size="large"
                    loading={loading}
                  >
                    Login
                  </ActionButton>
                </Form>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginTop: "1rem",
                    justifyContent: "start",
                  }}
                >
                  {/* <p style={{ margin: 0 }}>Don’t have an account?</p>
                  <Button type="link">Sign up now!</Button> */}
                </div>
              </>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
