import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalstorage";

const CurrentShopIdContext = createContext("");

const CurrentShopIdProvider = ({ children }) => {
  const [state, setState] = useLocalStorage("currentShopId", "");

  return (
    <CurrentShopIdContext.Provider value={[state, setState]}>
      {children}
    </CurrentShopIdContext.Provider>
  );
};

export default CurrentShopIdProvider;

export const useCurrentShopId = () => {
  const context = useContext(CurrentShopIdContext);
  if (!context) {
    throw new Error(
      "useCurrentShopId must be used within a CurrentShopIdProvider"
    );
  }
  return [context[0], context[1]];
};
