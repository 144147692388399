import React from 'react';
import {Button} from 'antd';


const ActionButton = ({style,children,...rest}) => {
    return ( 
        <Button type="primary" size='large' style={{color:'#fff', background:"#EF4667",border:0,...style}} {...rest}>
            {children}
        </Button>
     );
}
 
export default ActionButton;