import { INITIAL_VALUES, TEMPLATE_NAME } from "./formTypes";

const initialState = {
  templateName: "",
  initialValues: {},
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_NAME:
      return {
        ...state,
        templateName: action.payload,
      };
    case INITIAL_VALUES:
      return {
        ...state,
        initialValues: action.payload,
      };
    default:
      return state;
  }
};

export default formReducer;
