import { combineReducers } from "redux";
import formReducer from "./form/formReducer";
import loginReducer from "../redux/login/loginReducer";
import postMessageReducer from "./postMessage/postMessageReducer";

const rootReducer = combineReducers({
  form: formReducer,
  postMessage: postMessageReducer,
  logger: loginReducer,
});

export default rootReducer;
