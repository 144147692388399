import { produce } from "immer";
import {
  SET_POST_MESSAGE,
  SET_TEMPLATE_ORDER,
  UPDATE_ABOUT_US_TEMPLATE,
  UPDATE_PALETTE,
  UPDATE_POST_MESSAGE,
  UPDATE_CLONE_POST_MESSAGE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_CONTENT,
  UPDATE_WELCOME_TEMPLATE,
  UPDATE_CUSTOM_PALETTE,
  UPDATE_SECTION_SETTINGS,
  UPDATE_TEMPLATE_ACTIVE,
  SET_TO_INITIAL,
  UPDATE_FONT_SETTINGS,
  UPDATE_ANIMATION_SETTINGS,
} from "./postMessageType";

const websiteData = {
  contactUs: {
    templateId: "contactUs_001",
    pageProps: {
      customerEmailId: true,
      customerName: true,
      shortNote: true,
    },
  },
  aboutUs: {
    active: true,
    pageProps: {
      whatsappSwitch: false,
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi",
      heading: "Hello world",
      imageUri: "",
      imageUrl: "",
    },
    templateId: "aboutUs_001",
    templateName: "Classic Landing",
  },
  about: {
    active: true,
    pageProps: {
      imgName: "b52ae336-346d-437b-b45a-6cf828b61849.jpg",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit Lorem ipsum dolor sit amet consectetur adipisicing elit Lorem ipsum dolor sit amet consectetur adipisicing elit",
      heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit!!",
      imageUrl: "",
    },
    templateId: "about_001",
    templateName: "Shift Left",
  },
  ourClients: {
    templateName: "Compact",
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "4b8d3505-e597-4073-970b-d0fefc05ec65",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "4b8d3505-e597-4073-970b-d0fefc05ec65",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "4b8d3505-e597-4073-970b-d0fefc05ec65",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "4b8d3505-e597-4073-970b-d0fefc05ec65",
        },
      ],
    },
    templateId: "ourClients_002",
  },
  clientTestimonials: {
    templateName: "Highlighter",
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor sit amet!!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "eecccc49-a4ad-41e3-bb0d-10e0281d7b15",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor sit amet!!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "eecccc49-a4ad-41e3-bb0d-10e0281d7b15",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor sit amet!!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "eecccc49-a4ad-41e3-bb0d-10e0281d7b15",
        },
        {
          imgName: "",
          imageUri: "",
          heading: "Lorem ipsum dolor sit amet!!!",
          imageUrl: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "eecccc49-a4ad-41e3-bb0d-10e0281d7b15",
        },
      ],
    },
    templateId: "clientTestimonials_001",
  },
  externalLink: {
    active: true,
    pageProps: {
      description: "Abcd",
      heading: "Check our external social sites link",
      lableName: "Click here",
      urlLink: "Facebook.com",
    },
    templateId: "donation_001",
    templateName: "donationTemplate",
  },
  videoGallery: {
    templateName: "Shade",
    active: true,
    pageProps: {
      cardData: [
        {
          videoUrl: "",
          heading: "",
          description: "",
          id: "1b575918-17a9-46c5-982b-e91f36263d26",
        },
        {
          videoUrl: "",
          heading: "",
          description: "",
          id: "f03a47d2-e9c5-4590-81bd-5c5615d934d1",
        },
        {
          videoUrl: "",
          heading: "",
          description: "",
          id: "1b575918-17a9-46c5-982b-e91f36263d26",
        },
        {
          videoUrl: "",
          heading: "",
          description: "",
          id: "414043c2-bcea-4ac2-a48b-9ee2733f8487",
        },
      ],
    },
    templateId: "videoGallery_001",
  },
  ourBrands: {
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "8863ba41-3ed5-403a-8a2d-360f7977e402.jpg",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUri: "",
          id: "c4961d7f-b215-445b-8375-098876b81096",
          heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUrl: "",
        },
        {
          imgName: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",

          id: "59dc05ad-4e65-447f-ad97-b0fc89c0f526",
          heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUri: "",
          imageUrl: "",
        },
        {
          imgName: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",

          id: "59dc05ad-4e65-447f-ad97-b0fc89c0f526",
          heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUri: "",
          imageUrl: "",
        },
        {
          imgName: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",

          id: "59dc05ad-4e65-447f-ad97-b0fc89c0f526",
          heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUri: "",
          imageUrl: "",
        },
        {
          imgName: "",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          id: "59dc05ad-4e65-447f-ad97-b0fc89c0f526",
          heading: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
          imageUri: "",
          imageUrl: "",
        },
      ],
    },
    templateId: "ourBrands_001",
    templateName: "Simple and clean",
  },
  ourServices: {
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "83f38790-e6a2-4e08-a4f6-a1572fd25eba.jpeg",
          description: "smndcsdcmdscb",
          imageUri: "",
          id: "db22f4e9-0fb5-4f16-897d-f2b5c886076b",
          heading: "sdcnsd,csd,c",
          imageUrl: "",
        },
        {
          imgName: "883dc767-974f-4461-8916-88cd727868a7.jpeg",
          description: "nmzbczmbcx",
          imageUri: "",
          id: "7a954e0b-13ee-41e2-9cf1-5ea1dea659a9",
          heading: "zdcmbbmzdc m",
          imageUrl: "",
        },
      ],
    },
    templateId: "ourServices_001",
    templateName: "Squarescape",
  },
  socialMedia: {
    active: true,
    pageProps: {
      cardData: [
        {
          profileLink: "www.facebook.com/ghoshakindia",
          profileName: "Ghoshak",
          followers: "",
          id: "693c3c70-c408-495c-9a13-0cfe8f126c54",
          socialMedia: "Facebook",
          showOthers: false,
        },
      ],
    },
    templateId: "SocialMedia_001",
    templateName: "Classic Landing",
  },
  poweredByGhoshak: {
    templateId: "default",
    active: true,
    pageProps: {},
  },
  ourGallery: {
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0w.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "f20204c0-1d54-417d-a2c0-7fa52ef53cb0.jpg",
          description: "",
          imageUri: "",
          id: "0c15d905-cabe-4f4b-a92a-3c48b808471a",
          heading: "",
          imageUrl: "",
        },
      ],
    },
    templateId: "ourGallery_001",
    templateName: "Compact view",
  },
  topNotification: {
    active: true,
    pageProps: {
      cardData: [
        {
          bgColor: "#ddbfff",
          notificationDetails: "Exciting offers!!!",
          showTimer: false,
          displayArea: "top",
          startTime: "",
          endTime: "",
          textColor: "",
        },
      ],
    },
    templateId: "topBar_001",
    templateName: "Squarescape",
  },
  phoneNumber: {
    templateId: "default",
    active: false,
    pageProps: {},
  },
  showPrice: {
    templateId: "default",
    active: true,
    pageProps: {},
  },
  colorTheme: {
    primaryColor: "#D0B49F",
    backgroundColor: "#F1E7DD",
    id: 6,
    actionColor: "#9C6644",
    paletteName: "Earthy theme",
    accentColor1: "#3F3F3F",
  },
  ourProjects: {
    active: true,
    pageProps: {
      cardData: [
        {
          imgName: "",
          description: "",
          imageUri: "",
          id: "0c2ef966-a068-41a2-be16-043bf62f7bc9",
          heading: "",
          imageUrl: "",
        },
        {
          imgName: "",
          description: "",
          imageUri: "",
          id: "c36c5f6f-fe6e-41ab-bbc3-cc5b8c60b62e",
          heading: "",
          imageUrl: "",
        },
      ],
    },
    templateId: "ourProjects_001",
    templateName: "Miniature pack",
  },
  colorPallet: [
    {
      primaryColor: "#a364a8",
      backgroundColor: "#6d0060",
      id: 1,
      actionColor: "#EF4667",
      paletteName: "Pleasant",
      accentColor1: "#3F3F3F",
    },
    {
      primaryColor: "#DADED4",
      backgroundColor: "#FFFFFF",
      id: 2,
      actionColor: "#39603D",
      paletteName: "Optimal Green",
      accentColor1: "#3C403D",
    },
    {
      primaryColor: "#FFC700",
      backgroundColor: "#FFFFFF",
      id: 3,
      actionColor: "#404040",
      paletteName: "Classic Yellow",
      accentColor1: "#3F3F3F",
    },
    {
      primaryColor: "#C8D8E4",
      backgroundColor: "#F2F2F2",
      id: 4,
      actionColor: "#2B6777",
      paletteName: "Trustworthy",
      accentColor1: "#3C403D",
    },
    {
      primaryColor: "#284B63",
      backgroundColor: "#231F20",
      id: 5,
      actionColor: "#2B6777",
      paletteName: "Optimal Dark theme",
      accentColor1: "#FFFFFF",
    },
    {
      primaryColor: "#D0B49F",
      backgroundColor: "#F1E7DD",
      id: 6,
      actionColor: "#9C6644",
      paletteName: "Earthy theme",
      accentColor1: "#3F3F3F",
    },
    {
      primaryColor: "#66666E",
      backgroundColor: "#9999A1",
      id: 7,
      actionColor: "#FFFFFF",
      paletteName: "Gray theme",
      accentColor1: "#FFFFFF",
    },
    {
      primaryColor: "#C8C8C8",
      backgroundColor: "#4C4C4C",
      id: 8,
      actionColor: "#FFFFFF",
      paletteName: "Purpleish theme",
      accentColor1: "#3F3F3F",
    },
    {
      primaryColor: "#C8C8C8",
      backgroundColor: "#FFFFFF",
      id: 9,
      actionColor: "#3F3F3F",
      paletteName: "Cool Gray",
      accentColor1: "#3F3F3F",
    },
    {
      primaryColor: "#F3A745",
      backgroundColor: "#FCFCFC",
      id: 10,
      actionColor: "#FCFCFC",
      paletteName: "Mango theme",
      accentColor1: "#3F3F3F",
    },
  ],
  order: [
    "aboutUs",
    "about",
    "ourTeam",
    "onlineProduct",
    "ourServices",
    "ourProjects",
    "ourBrands",
    "ourClients",
    "ourGallery",
    "clientTestimonials",
    "videoGallery",
    "socialMedia",
    "contactUs",
    "externalLink",
  ],
  appStore: {
    active: true,
    pageProps: {
      description:
        "Conveniently transform frictionless mindshare after orthogonal manufactured products.",
      heading: "Download Our App",
      urlLink: "",
      imageUrl: "",
    },
    templateId: "appStore_001",
    templateName: "App store",
  },
  scrollToView: "",
};

const initialState = {
  postMessage: {
    shopDetails: {
      shopName: "Shop name",
      shopAddress:
        "Shop address Eg:Thousand lights road, Eldorado building, Nungambakkam, Chennai, Tamil nadu, India - 600034.",
      shopId: "feae5135-fd94-4b76-8af4-1405ebfd96a4",
      shopType: "Supermarket",
      websiteTemplate: websiteData,
      shopTimings: {},
    },
    pageProps: websiteData,
  },
  clonePostMessage: {},
  defaultPostMessage: {
    shopDetails: {
      shopName: "Shop name",
      shopAddress:
        "Shop address Eg:Thousand lights road, Eldorado building, Nungambakkam, Chennai, Tamil nadu, India - 600034.",
      shopId: "feae5135-fd94-4b76-8af4-1405ebfd96a4",
      shopType: "Supermarket",
      websiteTemplate: websiteData,
      shopTimings: {},
    },
    pageProps: websiteData,
  },
};

const formReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POST_MESSAGE:
      return {
        ...state,
        postMessage: action.payload,
      };
    case UPDATE_CLONE_POST_MESSAGE:
      return {
        ...state,
        clonePostMessage: action.payload,
      };
    case SET_POST_MESSAGE:
      return {
        ...state,
        postMessage: action.payload,
      };
    case SET_TEMPLATE_ORDER:
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            order: action.payload,
          },
        },
      };
    case UPDATE_WELCOME_TEMPLATE:
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            aboutUs: {
              ...state.postMessage.pageProps.aboutUs,
              ...action.payload,
            },
          },
        },
      };
    case UPDATE_ABOUT_US_TEMPLATE: {
      console.log("UPDATE_ABOUT_US_TEMPLATE", action.payload);
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            about: {
              ...state.postMessage.pageProps.about,
              ...action.payload,
            },
          },
        },
      };
    }

    case UPDATE_TEMPLATE: {
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            [action.payload.key]: {
              ...state.postMessage.pageProps[action.payload.key],
              templateId: action.payload.templateId,
              templateName: action.payload.templateName,
            },
          },
        },
      };
    }
    case UPDATE_TEMPLATE_ACTIVE:
      state.postMessage.pageProps[action.payload.key] = action.payload.content;
      return state;
    case UPDATE_TEMPLATE_CONTENT:
      state.postMessage.pageProps[action.payload.key] = action.payload.content;
      return state;
    case UPDATE_PALETTE:
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            colorTheme:
              state.postMessage.pageProps.colorPallet[action.payload - 1],
          },
        },
      };
    case UPDATE_CUSTOM_PALETTE:
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            colorTheme: {
              ...state.postMessage.pageProps?.colorTheme,
              ...action.payload,
            },
          },
        },
      };
    case "SCROLL_TO_VIEW": {
      console.log("scrollToView", action.payload);
      return {
        ...state,
        scrollToView: action.payload,
      };
    }

    case "UPDATE_ONLINE_STORE_SECTION_SETTINGS": {
      const pageProps = state.postMessage.pageProps[action.payload.key]
        ? state.postMessage.pageProps[action.payload.key]
        : websiteData[action.payload.key];
      console.log("test111", pageProps);
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            [action.payload.key]: {
              ...pageProps,
              active: action.payload.mode,
              templateId: action.payload.templateId,
            },
          },
        },
      };
    }

    case UPDATE_FONT_SETTINGS: {
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            fontSettings: {
              ...state.postMessage.pageProps?.fontSettings,
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };
    }

    case UPDATE_ANIMATION_SETTINGS: {
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            animationSettings: {
              active: action.payload.value,
            },
          },
        },
      };
    }

    case UPDATE_SECTION_SETTINGS: {
      const pageProps = state.postMessage.pageProps[action.payload.key]
        ? state.postMessage.pageProps[action.payload.key]
        : websiteData[action.payload.key];
      const order = [...state.postMessage.pageProps.order];
      if (!state.postMessage.pageProps["order"].includes(action.payload.key)) {
        order.push(action.payload.key);
      }
      return {
        ...state,
        postMessage: {
          ...state.postMessage,
          pageProps: {
            ...state.postMessage.pageProps,
            order,
            [action.payload.key]: {
              ...pageProps,
              active: action.payload.mode,
            },
          },
        },
      };
    }
    case SET_TO_INITIAL: {
      return initialState;
    }

    default:
      return state;
  }
});

export default formReducer;
