import { Drawer } from "antd";
import React, { useState } from "react";
import useLazyImport from "../../hooks/useLazyImport";

const forms = {
  header: "./header.js",
  aboutUs: "./welcome-page.js",
  ourProjects: "./our-project.js",
  ourServices: "./our-services.js",
  ourClients: "./clients.js",
  ourBrands: "./brands.js",
  socialMedia: "./social-media.js",
  videoGallery: "./videos.js",
  clientTestimonials: "./testimonials.js",
  ourContacts: "./contact-us.js",
  ourGallery: "./gallery.js",
  about: "./about-us.js",
  topNotification: "./top-notification.js",
  externalLink: "./externalLink.js",
  OurPartners: "./our-partners.js",
  appStore: "./app-store.js",
  onlineProduct: "./onlineProducts.js",
};

const DynamicModal = React.forwardRef((props, ref) => {
  const onClose = () => {
    setVisible(false);
    props.setEditTemplate("");
  };
  const LazyComponent = useLazyImport(
    () => import(`${forms[props.templateName]}`),
    onClose,
    props.getUserDetails,
    props.setStepIndex
  );
  const [visible, setVisible] = useState(true);
  return (
    <div id="container" ref={ref}>
      <Drawer
        className="formContainer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{
          padding: "0 24px 24px",
          paddingBottom: "4rem",
        }}
        mask={false}
        getContainer={false}
      >
        {LazyComponent}
      </Drawer>
    </div>
  );
});

export default React.memo(DynamicModal);
