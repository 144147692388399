import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Button, Col, Form, Row, Input, message } from "antd";
import ActionButton from "./Buttons/button";
import Bgimage from "../assets/Login/Group.svg";
import Logo from "../assets/Logo.svg";
import { FaPhoneAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { IS_LOGGEDIN } from "../redux/login/loginType";
import { RiLockPasswordFill } from "react-icons/ri";
import { useCurrentLoginType } from "../provider/currentLoginTypeProvider";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [, setLoginType] = useCurrentLoginType(false);

  const onLoginSubmit = (values) => {
    if (values.phone_number) {
      setLoading(true);
      LogInValidation(values.phone_number, values.password);
    }
    setPhoneNumber(values.phone_number);
  };

  async function LogInValidation(phoneNumber, password) {
    const params = {
      userPhoneNumber: "+91" + phoneNumber,
      password: password,
    };

    //Kirubakk26

    await Auth.signIn({
      username: params.userPhoneNumber,
      password: params.password,
    })
      .then((e) => {
        console.log("LogInValidation", e);
        setLoginType("internal");
        navigate("/internal");
        dispatch({
          type: IS_LOGGEDIN,
          payload: true,
        });
      })
      .catch((e) => {
        if (e.code === "NetworkError") {
          return alert("Please check your network connection and try again");
        }
        if (e.code === "UserNotFoundException") {
          message.warning("User Not Found!");
        } else {
          message.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleResetPassword = async () => {
    //setForgotPasswordLoading(true);
    try {
      const res = await Auth.forgotPassword("+" + 919003648482);
      //setResetPasswordModal(true);
      console.log("res", res);
    } catch (err) {
      console.log("Error:", err);
      alert("Something went wrong. Please try again");
    } finally {
      //setForgotPasswordLoading(false);
    }
  };

  return (
    <div>
      <Row
        style={{
          height: "100vh",
        }}
        align="middle"
      >
        <Col
          span={12}
          style={{
            background: `url(${Bgimage}), linear-gradient(180deg, #EF4667 0%, #A00E2A 84.79%, #7A0B20 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "inherit",
            backgroundPosition: "bottom left",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "3rem",
          }}
        >
          <h1
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#fff",
              lineHeight: "40px",
            }}
          >
            Ghoshak <br />
            Your Store Partner
          </h1>
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
              fontWeight: "400",
              fontFamily: "HK Grotesk",
              lineHeight: "24px",
            }}
          >
            Manage Sales, Inventory and Employees with ease; Engage customers
            and increase your revenue.
          </p>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col span={14}>
            <>
              <img src={Logo} width="88px" height="28px" alt="logo" />
              <h4
                style={{
                  color: "#98989E",
                  fontSize: "16px",
                  margin: "1rem 0",
                }}
              >
                Welcome Back!
              </h4>
              <h1
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Login
              </h1>
              <Form
                onFinish={onLoginSubmit}
                labelCol={{
                  span: 24,
                }}
              >
                <Form.Item
                  name={"phone_number"}
                  style={{
                    fontSize: "12px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please input a valid phone number!",
                    },
                    {
                      pattern: /^\S/,
                      required: true,
                      message:
                        "Empty space is not allowed as the first character",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={
                      <FaPhoneAlt
                        style={{
                          color: "#98989E",
                          marginRight: "8px",
                        }}
                      />
                    }
                    style={{
                      width: "100%",
                    }}
                    placeholder="Enter Your Mobile Number"
                  />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  style={{
                    fontSize: "12px",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={
                      <RiLockPasswordFill
                        style={{
                          color: "#98989E",
                          marginRight: "8px",
                        }}
                        size={24}
                      />
                    }
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Enter Your Password"
                  />
                </Form.Item>
                {/* <Button onClick={handleResetPassword}>Forgot Password?</Button> */}
                <ActionButton
                  htmlType="submit"
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  size="large"
                  loading={loading}
                >
                  Login
                </ActionButton>
              </Form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "1rem",
                  justifyContent: "start",
                }}
              >
                {/* <p style={{ margin: 0 }}>Don’t have an account?</p>
                  <Button type="link">Sign up now!</Button> */}
              </div>
            </>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
