import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const IframeComm = ({
  attributes,
  handleReceiveMessage,
  handleReady,
  postMessageData,
  serializeMessage,
  targetOrigin,
}) => {
  const frameRef = useRef(null);
  const previousPostMessage = useRef(null);
  useEffect(() => {
    window.addEventListener("message", handleReceiveMessageEvent);
    if (frameRef.current) {
      frameRef.current.addEventListener("load", handleLoad);
    }
    return () => {
      window.removeEventListener("message", handleReceiveMessageEvent);
    };
  }, [handleReceiveMessage]);

  useEffect(() => {
    // setTimeout(() => {
    if (frameRef.current && postMessageData !== "") {
      if (
        JSON.stringify(postMessageData) !==
        JSON.stringify(previousPostMessage.current)
      ) {
        sendMessage(postMessageData);
        previousPostMessage.current = postMessageData;
      }
    }
    // }, 5000);
  }, [postMessageData]);

  const handleReceiveMessageEvent = (event) => {
    if (handleReceiveMessage) {
      handleReceiveMessage(event);
    }
  };

  const handleLoad = () => {
    if (handleReady) {
      handleReady();
    }
    // TODO: Look into doing a syn-ack TCP-like handshake
    //       to make sure iFrame is ready to REALLY accept messages, not just loaded.
    // send intial props when iframe loads
    sendMessage(postMessageData);
  };

  const serializePostMessageData = (data) => {
    // Rely on the browser's built-in structured clone algorithm for serialization of the
    // message as described in
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    if (!serializeMessage) {
      return data;
    }

    // To be on the safe side we can also ignore the browser's built-in serialization feature
    // and serialize the data manually.
    if (typeof data === "object") {
      return JSON.stringify(data);
    } else if (typeof data === "string") {
      return data;
    } else {
      return `${data}`;
    }
  };

  const sendMessage = (postMessageData) => {
    const serializedData = serializePostMessageData(postMessageData);
    if (frameRef.current) {
      frameRef.current.contentWindow.postMessage(serializedData, targetOrigin);
    }
  };

  const defaultAttributes = {
    allowFullScreen: false,
    frameBorder: 0,
  };

  const mergedAttributes = Object.assign({}, defaultAttributes, attributes);

  return <iframe ref={frameRef} {...mergedAttributes} />;
};

export default IframeComm;
