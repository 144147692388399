import { IS_LOGGEDIN, SET_LOGGEDIN } from "./loginType";
let initialState = {
  isLoggedIn: false,
  userType: "",
};

const isLoggedInReducer = (state = initialState, action) => {
  console.log("actionLoginReducers", action);
  switch (action.type) {
    case IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        userType: action.payload,
      };
    default:
      return state;
  }
};

export default isLoggedInReducer;
