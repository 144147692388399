import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalstorage";

const CurrentLoginTypeContext = createContext("");

const CurrentLoginTypeProvider = ({ children }) => {
  const [state, setState] = useLocalStorage("currentLoginType", "");

  return (
    <CurrentLoginTypeContext.Provider value={[state, setState]}>
      {children}
    </CurrentLoginTypeContext.Provider>
  );
};

export default CurrentLoginTypeProvider;

export const useCurrentLoginType = () => {
  const context = useContext(CurrentLoginTypeContext);
  if (!context) {
    throw new Error(
      "useCurrentShopId must be used within a CurrentShopIdProvider"
    );
  }
  return [context[0], context[1]];
};
